<template>
  <div>
    <br />
    <ul class="comments">
      <li>
        <div><b>Portfolio</b></div>
      </li>
      <li
        v-for="(i, index) in items"
        :key="index">
        <div v-html="i"></div>
      </li>
      <!-- <li>
        has a soft spot for family, football, combat sports, Japan and ofcourse
        programming ♥
      </li>
      <li>loves building APIs</li>
      <li>hates dirty code</li> -->
    </ul>

    <br />
  </div>
</template>

<style lang="scss">
.comments {
  padding: 0;
  list-style: none;
  color: #636363;
  font-size: 14px;
  margin: 0;

  li {
    position: relative;
    display: flex;

    a {
      color: #636363;
    }

    &::before {
      content: "//";
      margin-right: 5px;
    }
  }
}
</style>

<script>
// const API_URL = 'https://api.chucknorris.io/jokes/random'
// const API_TIMEOUT = 5000 // 5 seconds

// const abortController = new AbortController()

export default {
  inject: ['terminate'],

  data () {
    return {
      items: [
        '<a target="_blank" href="https://bayramfysiotherapie.nl/">Bayram Fysiotherapie</a>',
        '<a target="_blank" href=\'https://goldverandas.nl/\'>Gold Veranda\'s</a>',
        '<a target="_blank" href="https://gtgarage.nl/">GT Garage</a>',
        'local projects (CMS, blog, etc.)',
        'private projects for my employer'
      ]
    }
  },
  mounted () {
    // var body = document.querySelector('body')
    // body.classList.add('fadeProfileImage')
    // setTimeout(() => {
    //   body.classList.remove('fadeProfileImage')
    // }, 5000)
    this.terminate()
  },

  methods: {}
}
</script>
