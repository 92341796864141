<template>
  <div>
    <br />
    <ul class="comments">
      <li>
        <div><b>Batuhan Ciftci</b></div>
      </li>
      <li><div>passionate developer</div></li>
      <li><div>22 years old</div></li>

      <li><div>lives in the Netherlands</div></li>
      <li>
        <div>
          graduated from
          <a
            target="_blank"
            href="https://www.sintlucas.nl/">SintLucas</a> in
          Eindhoven
        </div>
      </li>
      <li>
        <div>
          currently working for
          <a
            target="_blank"
            href="https://www.websentiment.nl/">WebSentiment</a>
          as Backend Developer
        </div>
      </li>
      <li>
        <div>
          previously worked for
          <a
            target="_blank"
            href="https://www.websentiment.nl/">WebSentiment</a> as Frontend Developer
        </div>
      </li>
      <li>
        has a soft spot for family, football, combat sports, Japan and ofcourse
        programming ♥
      </li>
      <li>loves building APIs</li>
      <li>very studious</li>
      <li>sometimes too eager and ambitious</li>
      <li>speaks Dutch (fluent), English (I guess reasonable), Turkish (fluent) and a little bit German</li>
      <li>visited Japan from December 2019 to January 2020 and fell in love with the country</li>
    </ul>

    <br />
  </div>
</template>

<script>
// const API_URL = 'https://api.chucknorris.io/jokes/random'
// const API_TIMEOUT = 5000 // 5 seconds

// const abortController = new AbortController()

export default {
  inject: ['terminate'],

  data: () => ({}),

  mounted () {
    var body = document.querySelector('body')
    body.classList.add('fadeProfileImage')
    setTimeout(() => {
      body.classList.remove('fadeProfileImage')
      this.terminate()
    }, 5000)
  },

  methods: {}
}
</script>
