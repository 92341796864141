<template>
  <div>
    <br />
    <ul class="comments">
      <li>
        <div><b>Skills</b></div>
      </li>
      <li
        v-for="(s, index) in filteredSkills"
        :key="index">
        <div>{{ s }}</div>
      </li>
      <!-- <li>
        has a soft spot for family, football, combat sports, Japan and ofcourse
        programming ♥
      </li>
      <li>loves building APIs</li>
      <li>hates dirty code</li> -->
    </ul>

    <br />
  </div>
</template>

<script>
// const API_URL = 'https://api.chucknorris.io/jokes/random'
// const API_TIMEOUT = 5000 // 5 seconds

// const abortController = new AbortController()

export default {
  inject: ['terminate'],

  data () {
    return {
      skills: [
        'HTML',
        'CSS',
        'SCSS',
        'Javascript',
        'jQuery',
        'Vue',
        'PHP',
        'Wordpress',
        'VB.net',
        'Adobe',
        'MySQL',
        'Git',
        'Laravel',
        'Bootstrap',
        'Postman',
        'Flutter (basic)',
        'AngularJS (basic)',
        'Ionic',
        'SEO optimizing',
        'IIS',
        'Apache'
      ]
    }
  },
  computed: {
    filteredSkills: {
      get () {
        return [...this.skills].sort((a, b) => a.localeCompare(b))
      }
    }
  },

  mounted () {
    // var body = document.querySelector('body')
    // body.classList.add('fadeProfileImage')
    // setTimeout(() => {
    //   body.classList.remove('fadeProfileImage')
    // }, 5000)
    this.terminate()
  },

  methods: {}
}
</script>
