<template>
  <div>
    <br />
    <ul class="comments">
      <li>
        <div><b>Contact</b></div>
      </li>
      <li
        v-for="(i, index) in items"
        :key="index">
        <div v-html="i"></div>
      </li>
      <!-- <li>
        has a soft spot for family, football, combat sports, Japan and ofcourse
        programming ♥
      </li>
      <li>loves building APIs</li>
      <li>hates dirty code</li> -->
    </ul>

    <br />
  </div>
</template>

<script>
// const API_URL = 'https://api.chucknorris.io/jokes/random'
// const API_TIMEOUT = 5000 // 5 seconds

// const abortController = new AbortController()

export default {
  inject: ['terminate'],

  data () {
    return {
      items: [
        '<a target="_blank" href="https://nl.linkedin.com/in/batuhan-ciftci-452bb8143">LinkedIn</a>',
        'batje.ciftci@gmail.com',
        '+31 641095863'
      ]
    }
  },
  mounted () {
    // var body = document.querySelector('body')
    // body.classList.add('fadeProfileImage')
    // setTimeout(() => {
    //   body.classList.remove('fadeProfileImage')
    // }, 5000)
    this.terminate()
  },

  methods: {}
}
</script>
